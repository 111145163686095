import React from "react";
import Layout from "../components/Layout";
import Image from "../components/Image";
import SEO from "../components/SEO";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Lucy Doodles 🐩" />
      <h1>Gallery</h1>
      <div style={{ maxWidth: "300px", marginBottom: "1.45rem" }}>
        <Image />
      </div>
      <h1>Reviews</h1>
      <p>Absolute cuties! 5/5, would recommend to anyone! - Susan H.</p>
      {/* eslint-disable-next-line */}
      <p>We fell in love with our goldendoodle Max, he's the best! Sandra was an amazing help with making sure he was trained and ready.</p>
      <h1>Contact Sandra</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type="email" placeholder="email" />
        <input type="text" placeholder="title" />
        <textarea placeholder="description" />
        <button type="submit">Send</button>
      </form>
    </Layout>
  );
};

export default IndexPage;
